@import "minima.scss";

body {
  background: url(fish-tank.jpg) no-repeat center center fixed;
  background-size: cover;
}

div.wrapper {
  background-color: rgba(100%, 100%, 100%, 0.90);

  @supports (-webkit-backdrop-filter: blur(10px)) {
    background-color: rgba(100%, 100%, 100%, $translucent-opacity);
    -webkit-backdrop-filter: blur(5px);
  }
  @supports (backdrop-filter: blur(10px)) {
    background-color: rgba(100%, 100%, 100%, $translucent-opacity);
    backdrop-filter: blur(5px);
  }

  border-radius: 5px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  max-width: calc(400px - (30px * 2));
  margin-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;
    margin-bottom: 0px;
  }

  img.avatar {
    border-radius: 50%;
    width: 72px;
    margin-right: 10px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  }

  div.container {
    padding-top: 30px;
    position: relative;
    text-align: center;

    display: flex;
    flex-direction: row;

    .icon {
      width: 48px;
      height: 48px;
      margin: auto;
      display: block;
    }

    svg.icon {
      fill: currentColor;
    }

    div {
      flex: 1 1 auto;
      width: 33.33333%;
      position: relative;
      float: left;
      align-content: center;
    }
  }
}

@media screen and (max-width: 430px) {
  div.wrapper {
    margin-right: 0px;
    border-radius: 0px;
    max-width: none;
  }
}
